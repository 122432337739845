import React, { PureComponent } from 'react';
import cx from 'classnames';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Helmet } from 'react-helmet';
import { RouteChildrenProps } from 'react-router';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import property from 'lodash/property';
import concat from 'lodash/concat';
import SweetAlert from '@sweetalert/with-react';

import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';
import Alert from 'helpers/alert';
import { formattedNumberWithZero } from 'helpers/utils';
import API from 'api';
import { IAnnualPlan, IBrand, IFrontendAnnualPlan, IOption, IPerformanceCalculationType, IProfile } from 'types';
import CampaignsTable from './Panels/CampaignsTable';
import TvCampaignParams from './Panels/TvCampaignParams';
import OLVCampaignParams, { platformNullableProps } from './Panels/OLVCampaignParams';
import { actualYear } from 'helpers/yearOptions';
import {
  getPrices as getTvPrices,
  loadTvPricesByChannelAndDistributionAndMonths,
  makeSelectChannelSource
} from '../helpers/tv';
import { DEFAULT_LENGTH_SHARES, FEDERAL_CHANNEL } from '../helpers/constants';
import { isCbuValid, isTvOlvValid } from '../helpers/chart-validation';
import { convertPlatform } from '../helpers/olv';
import BaseParameters from './Panels/BaseParameters';
import CampaignGoals from './Panels/CampaignGoals';
import CalculatedValues from './Panels/CalculatedValues';

import {
  CampaignID,
  CampaignUpdate,
  CustomUniverse,
  FullChannelType,
  FullTargetAudience,
  GoalType,
  IBrandBudget,
  ICampaign,
  ICampaignPlots,
  IChannel,
  IChannelConfig,
  IFrequency,
  IGoal,
  ILengthShare,
  IMediaplanBaseParameters,
  INullableMomentRange,
  IPlatformConfig,
  ITargetAudience,
  MapCampaignToTargetAudienceTypes
} from '../types';

import classes from './AnnualPlanEdit.module.scss';
import Charts from './Panels/Charts';
import { cloneObject } from 'helpers/cloneObject';
import { Link } from 'react-router-dom';

const moment = extendMoment(Moment);
const restrictionKeys = [
  'fixPercentMin',
  'fixPercentMax',
  'fixPrimePercentMin',
  'fixPrimePercentMax',
  'floatPrimePercentMin',
  'floatPrimePercentMax',
  'channelPercentMin',
  'channelPercentMax'
];

type OwnProps = RouteChildrenProps<{ id: string; type: string }>;

type StateProps = {
  profile: IProfile;
};

type Props = OwnProps & StateProps;

interface IState {
  type: string;
  form: IMediaplanBaseParameters;

  isTradeBusied: boolean;
  isTradeWhiteList: boolean;
  isAdvertiserCounted: boolean;

  performanceCalculationType?: IPerformanceCalculationType;

  selectedCampaignID: string | number | null;
  allChannels: IChannel[];
  regionsOptions: Array<{ value: number; label: string; rgn: number | null }>;
  advertisersOptions: IOption[];
  allTargetAudiences: FullTargetAudience[];
  isShowErrors: boolean;
  annualBudget: number;
  selectedTargetAudiences: string;
  isOldTvRestrictions: boolean;
  monthsBudgetBarChartData: number[][];
  monthsBudgetBarChartSeries: string[];
  brandsBudget: IBrandBudget[];
  allBrands: IBrand[];
  retroDiffs: { from: number | null; to: number | null };
  allChannelTypes: FullChannelType[];
  allFrequencies: IFrequency[];
  allClosedFrequencies: IFrequency[];
  status:
  | 'DRAFT'
  | 'IN_QUEUE'
  | 'IN_PROGRESS'
  | 'COMPLETE'
  | 'ON_REVIEW'
  | 'ON_APPROVAL'
  | 'APPROVED'
  | 'STARTED'
  | 'ERROR';

  isValidLengthShares: boolean;
  isValidClosedFrequencies: boolean;
  targetReachIsOpen: boolean;
  isLoadingPrices: boolean;
  mapCampaignToTargetAudienceTypes: MapCampaignToTargetAudienceTypes;
  campaigns: ICampaign[];
  sellerRestrictions: Record<string, { min: number; max: number }>;
  isCalculatePalomarsReach: boolean;
  palomarsRetroDates: INullableMomentRange;
  customUniverse: CustomUniverse;
}

interface IData {
  code: string;
  name: string;
  version: number;
  advertiser: { id: number; name: string };
  author: any;
  annualPeriod: number;
  type: null;
  status: any;

  requestAlg: { iterations: number };
  dealRestrictions: null;
}

export type HandleCustomUniverseChange = {
  (fieldName: 'isEnabled', value: boolean): void;
  (fieldName: 'value', value: string): void;
};

class AnnualPlanEdit extends PureComponent<Props, IState> {
  state: IState = {
    type: 'MULTIMEDIA',
    form: {
      name: '',
      advertiser: null,
      iterations: 30000,
      year: actualYear
    },

    isTradeBusied: false,
    isTradeWhiteList: false,
    isAdvertiserCounted: false,

    performanceCalculationType: undefined,

    status: 'DRAFT',

    advertisersOptions: [],
    isShowErrors: false,

    annualBudget: 0,
    selectedTargetAudiences: '',
    monthsBudgetBarChartData: [],
    monthsBudgetBarChartSeries: [],
    brandsBudget: [],

    selectedCampaignID: null,

    regionsOptions: [],
    allBrands: [],
    allTargetAudiences: [],
    retroDiffs: { from: null, to: null },
    allChannelTypes: [],
    allChannels: [],
    allFrequencies: [],
    allClosedFrequencies: [],
    isOldTvRestrictions: false,

    isLoadingPrices: false,
    isValidLengthShares: false,
    isValidClosedFrequencies: true,
    targetReachIsOpen: true,
    mapCampaignToTargetAudienceTypes: {},
    campaigns: [],
    sellerRestrictions: {},
    isCalculatePalomarsReach: false,
    palomarsRetroDates: {
      startDate: null,
      endDate: null
    },
    customUniverse: {
      value: null,
      isEnabled: false,
    },
  };

  data: IData | null = null;

  mounted = false;

  defaultPlatforms = [];
  federalTargetAudiences = [];
  regionalTargetAudiences = [];

  // при создании валидируются все кампании
  // а при редактировании, кампании которые не были заселекчены не валидируются (валидации происходит при рендере)
  // т.е. такие кампании не изменились, а значит они остались валидными
  mapCampaignsChannelGroupsIsValid: Partial<Record<CampaignID, boolean>> = {};
  mapCampaignsToTvIsValid: Partial<
  Record<CampaignID, Record<string, boolean>>
  > = {};
  mapCampaignsToOLVTabsValidation: Partial<
  Record<CampaignID, Record<string, boolean>>
  > = {};
  hasEditableCampaigns: boolean = false;

  consts = {
    CAMPAIGNS_IMPORT_TEMPLATE_TYPE_NAME: 'CAMPAIGNS_IMP',
    DEAL_RESTRICTIONS_IMPORT_TEMPLATE_TYPE_NAME: 'DEAL_RES_IMP',
    CHANNEL_RESTRICTIONS_IMPORT_TEMPLATE_TYPE_NAME: 'CHANNEL_RES_IMP'
  };

  async componentDidMount () {
    this.mounted = true;

    await this.loadData();
    this.loadSellerRestrictions();

    if (this.props.profile.allowedMediaStatus === 'none') {
      const messageHtml = `<p>${localizeMessage({ id: 'errors.errorAccessDeniedWithSupport' })}: 
        <a href='mailto:support@aizek.io'>support@aizek.io</a></p>`;
      Alert.error(messageHtml, { html: true });
    }
  }

  componentDidUpdate (prevProps: Props, prevState: IState) {
    const { campaigns, palomarsRetroDates, selectedCampaignID } = this.state;

    const isChangedCampaignRetroDates =
      campaigns[0] &&
      prevState.campaigns[0] &&
      (campaigns[0].retroFrom !== prevState.campaigns[0].retroFrom ||
        campaigns[0].retroTo !== prevState.campaigns[0].retroTo);

    const isUnchangedPalomarsRetroDates =
      prevState.campaigns[0] &&
      !moment(palomarsRetroDates.startDate).diff(
        moment(prevState.campaigns[0].retroFrom)
      ) &&
      !moment(palomarsRetroDates.endDate).diff(
        moment(prevState.campaigns[0].retroTo)
      );

    const isUninitializedPalomarsRetroDates =
      !prevState.campaigns.length &&
      campaigns.length &&
      !palomarsRetroDates.startDate &&
      !palomarsRetroDates.endDate;

    if (
      (isChangedCampaignRetroDates && isUnchangedPalomarsRetroDates) ||
      isUninitializedPalomarsRetroDates
    ) {
      this.setDefaultPalomarsRetroDates();
    }
    if (prevState.selectedCampaignID !== selectedCampaignID) {
      this.loadSellerRestrictions();
    }

    if (prevProps.profile.extend.agency.id !== this.props.profile.extend.agency.id) {
      this.loadData();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  async loadChannels (selectedCampaign: ICampaign) {
    try {
      const years = uniq([
        Moment(selectedCampaign.from).year(),
        Moment(selectedCampaign.to).year(),
        Moment(selectedCampaign.retroFrom).year(),
        Moment(selectedCampaign.retroTo).year()
      ]);
      const channels = await API.channels.list({ max: 0, years });
      const allChannels: IChannel[] = channels.items.map(channel => {
        if (!channel.nameWithType || !channel.nameWithType.length) {
          channel.nameWithType = channel.name;

          if (channel.type && channel.type.name !== 'LOCAL') {
            channel.nameWithType += ` (${channel.type.name
              .slice(0, 3)
              .toLowerCase()})`;
          }
        }

        return channel;
      });

      const source = this.selectChannelSource(
        selectedCampaign.channel,
        allChannels
      );

      const defaultChannelConfig: IChannelConfig[] = source.map(channel => {
        const config: IChannelConfig = {
          restrictions: { isExcludedChannel: true },
          extraCharge: {
            isSFix: false
          },
          cpp: {
            advertiserBasePrice: null,
            additionalDiscount: null
          }
        };

        return config;
      });

      this.setState(prevState => ({
        ...prevState,
        allChannels,
        campaigns: prevState.campaigns.map(campaign => {
          if (campaign.id === selectedCampaign.id) {
            const newTV: ICampaign['tv']['byChannel'] = source.reduce(
              (acc, channel, index) => {
                const channelConfig = campaign.tv.byChannel[channel.id];
                const newChannelConfig = channelConfig
                  ? {
                    ...channelConfig,
                    restrictions: {
                      ...defaultChannelConfig[index].restrictions,
                      ...channelConfig.restrictions
                    },
                    extraCharge: {
                      ...defaultChannelConfig[index].extraCharge,
                      ...channelConfig.extraCharge
                    },
                    cpp: {
                      ...defaultChannelConfig[index].cpp,
                      ...channelConfig.cpp
                    }
                  }
                  : defaultChannelConfig[index];

                return { ...acc, [channel.id]: newChannelConfig };
              },
              {}
            );

            return { ...campaign, tv: { byChannel: newTV } };
          }

          return campaign;
        })
      }));
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingData' }));
    }
  }

  async loadData () {
    const { match } = this.props;
    const params = match!.params;

    try {
      const [
        advertisers,
        federalTargetAudiences,
        regionalTargetAudiences,
        retroDiffs,
        channelTypes,
        regions,
        { annualPlan, allBrands },
        platforms
      ] = await Promise.all([
        API.advertisers.list({
          max: 0,
          filter: {
            items: [`AGENCY_${this.props.profile.extend.agency.id}`]
          }
        }),
        API.personaTargetAudience.list(
          { max: 0, active: true, needUpdate: true, order: 'name' },
          'federal'
        ),
        API.personaTargetAudience.list(
          { max: 0, active: true, needUpdate: true, order: 'name' },
          'regional'
        ),
        API.annualPlans.getRetroDiffs(moment().format('DD-MM-YYYY')),
        API.channels.getTypes(),
        API.regions.list({ max: 0 }),
        params.id
          ? this.getAnnualPlan(params.id)
          : Promise.resolve<{
            annualPlan?: IAnnualPlan;
            allBrands: IBrand[];
          }>({ annualPlan: undefined, allBrands: [] }),
        API.adPlatforms.list()
      ]);

      if (!this.mounted) {
        return;
      }

      this.federalTargetAudiences = federalTargetAudiences.map(ta => ({
        ...ta,
        displayedName: ta.groupName ? `${ta.groupName} / ${ta.name}` : ta.name
      }));

      this.regionalTargetAudiences = regionalTargetAudiences.map(ta => ({
        ...ta,
        displayedName: ta.groupName ? `${ta.groupName} / ${ta.name}` : ta.name
      }));

      this.defaultPlatforms = platforms.items.map(convertPlatform);

      const advertisersOptions = advertisers.items.map(advertiser => ({
        value: advertiser.id,
        label: advertiser.name
      }));

      const allFrequencies = Array.from({ length: 20 }, (_, i) => i + 1).map(
        n => ({
          value: n,
          name: `${n}+`
        })
      );

      const allClosedFrequencies = Array.from(
        { length: 30 },
        (_, i) => i + 1
      ).map(n => ({
        value: n,
        name: String(n)
      }));

      const { type, form, campaigns } = await this.parseAnnualPlan(annualPlan, {
        advertisersOptions
      });

      const palomarsRetroFrom =
        annualPlan && annualPlan.palomarsRetroFrom
          ? moment(annualPlan.palomarsRetroFrom)
          : null;
      const palomarsRetroTo =
        annualPlan && annualPlan.palomarsRetroTo
          ? moment(annualPlan.palomarsRetroTo)
          : null;
      const campaignRetroFrom =
        campaigns[0] && campaigns[0].retroFrom
          ? moment(campaigns[0].retroFrom)
          : null;
      const campaignRetroTo =
        campaigns[0] && campaigns[0].retroTo
          ? moment(campaigns[0].retroTo)
          : null;

      this.setState(
        {
          campaigns,
          type,
          form,

          isTradeBusied: annualPlan?.isTradeBusied || false,
          isTradeWhiteList: annualPlan?.isTradeWhiteList || false,
          isAdvertiserCounted: annualPlan?.isAdvertiserCounted || false,
          //
          performanceCalculationType: annualPlan?.performanceCalculationType,

          // status: annualPlan?.status?.name || 'DRAFT',
          status: 'DRAFT',
          regionsOptions: regions.items.map(region => ({
            value: region.id,
            label: region.name,
            rgn: region.rgn
          })),
          advertisersOptions,
          retroDiffs,
          allChannelTypes: [
            {
              name: 'ALL',
              nameTranslation: localizeMessage({ id: 'allChannels' })
            },
            ...channelTypes
          ],
          allFrequencies,
          allClosedFrequencies,
          allBrands,
          isCalculatePalomarsReach: Boolean(
            annualPlan && annualPlan.isCalculatePalomarsReach
          ),
          palomarsRetroDates: {
            startDate: palomarsRetroFrom || campaignRetroFrom,
            endDate: palomarsRetroTo || campaignRetroTo
          },
          customUniverse: {
            isEnabled: annualPlan?.isCustomUniverse || false,
            value: annualPlan?.customUniverseValue ? Number(annualPlan.customUniverseValue) : null,
          },
        },
        () => {
          if (campaigns.length) {
            this.selectedCampaignAndLoadChannels(campaigns[0]);
          }
          this.recalculateBudgetChartData();
        }
      );
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingData' }));
    }
  }

  async loadSellerRestrictions () {
    const year = this.getYearForSelectedCampaign();
    const sellerRestrictionList = await API.sellers.restrictions(year);

    const sellerRestrictions = Array.isArray(sellerRestrictionList)
      ? sellerRestrictionList.reduce((hash, { channelId, min, max }) => {
        hash[channelId] = { min, max };

        return hash;
      }, {})
      : {};

    this.setState({
      sellerRestrictions
    });
  }

  async getAnnualPlan (
    id: string
  ): Promise<{ annualPlan: IFrontendAnnualPlan; allBrands: IBrand[] }> {
    const annualPlan = await API.annualPlans.get(id);

    const allBrandsData = annualPlan.advertiser
      ? await API.brands.listByAdvertiser(
        annualPlan.advertiser.id,
        { max: 0, agencyKeycloakId: this.props.profile.extend.agency.id }
      )
      : { items: [] };

    return {
      annualPlan,
      allBrands: allBrandsData.items
    };
  }

  handleUpdatePricesLoadingStatus = (isLoadingPrices: boolean) => {
    this.setState({ isLoadingPrices });
  };

  handleUpdateHasEditableCampaigns = (hasEditableCampaigns: boolean) => {
    this.hasEditableCampaigns = hasEditableCampaigns;
  };

  updateTargetAudienceList = (isFederalChannels: boolean = true) => {
    const allTargetAudiences = isFederalChannels
      ? this.federalTargetAudiences
      : this.regionalTargetAudiences;
    this.setState({ allTargetAudiences });
  };

  loadTargetAudienceType = async (
    campaignID: string | number,
    retroFrom: string,
    retroTo: string,
    targetAudience: ITargetAudience,
    isNewTaName: boolean,
    isDontShowAlert?: boolean
  ) => {
    this.setState(prevProps => ({
      ...prevProps,
      mapCampaignToTargetAudienceTypes: {
        ...prevProps.mapCampaignToTargetAudienceTypes,
        [targetAudience.id]: undefined
      }
    }));

    const { volumeSize, targetAudienceType } = await new Promise(resolve => {
      API.targetAudiences
        .volumeByRetroPeriod(
          {
            id: targetAudience.id,
            retroFrom,
            retroTo
          },
          true
        )
        .then(data => {
          resolve({
            targetAudienceType: data.volumeType,
            volumeSize: data.size
          });
        })
        .catch(() => {
          resolve({
            targetAudienceType: 'ERROR',
            volumeSize: null
          });
        });
    });

    if (!isDontShowAlert) {
      if (isNewTaName && volumeSize === 0) {
        Alert.error(
          localizeMessage(
            { id: 'annualPlanEdit.fields.targetAudience.alerts.missingData' },
            {
              displayedName: targetAudience.displayedName
            }
          )
        );
      } else {
        Alert.success(
          localizeMessage(
            { id: 'annualPlanEdit.fields.targetAudience.alerts.recalculated' },
            {
              size: volumeSize
            }
          )
        );
      }
    }

    const newTargetAudienceType = {
      type: targetAudienceType,
      volumeSize: volumeSize || null
    };
    this.setState(prevProps => ({
      ...prevProps,
      mapCampaignToTargetAudienceTypes: {
        ...prevProps.mapCampaignToTargetAudienceTypes,
        [targetAudience.id]: newTargetAudienceType
      }
    }));
  };

  async parseAnnualPlan (dataWithMediaplans, { advertisersOptions }) {
    if (!dataWithMediaplans) {
      return {
        campaigns: [],
        type: this.props.match!.params.type || 'MULTIMEDIA',
        form: {
          name: '',
          advertiser: null,
          iterations: 30000,
          year: actualYear
        }
      };
    }

    const { mediaplans: serverCampaigns, ...data } = dataWithMediaplans;

    serverCampaigns.forEach(campaign => {
      this.loadTargetAudienceType(
        campaign.id,
        campaign.retroFrom,
        campaign.retroTo,
        campaign.targetAudience,
        false,
        true
      );

      (campaign.additionalTA || []).forEach(ta => this.loadTargetAudienceType(
        campaign.id,
        campaign.retroFrom,
        campaign.retroTo,
        ta,
        false,
        true
      )
      );
    });
    const campaigns: ICampaign[] = serverCampaigns.map(
      (campaign): ICampaign => {
        const convertRestriction = restriction => {
          if (
            !Object.keys(restriction.seasonalDiscounts).length ||
            !Object.keys(restriction.extraCharge).length
          ) {
            return undefined;
          }
          const prefixes = restrictionKeys.map(key => key.slice(0, -3));

          const convertedRestriction = Object.keys(
            restriction.restrictions
          ).reduce((acc, key) => {
            if (prefixes.includes(key)) {
              return {
                ...acc,
                [`${key}Min`]: restriction.restrictions[key].min,
                [`${key}Max`]: restriction.restrictions[key].max
              };
            }

            return { ...acc, [key]: restriction.restrictions[key] };
          }, {});

          return { ...restriction, restrictions: convertedRestriction };
        };

        const convertByChannel = byChannel => Object.keys(byChannel).reduce((acc, key) => {
          const convertedRestriction = convertRestriction(byChannel[key]);

          return convertedRestriction
            ? { ...acc, [key]: convertedRestriction }
            : acc;
        }, {});

        const convertOLV = olv => {
          if (!olv) {
            return [...this.defaultPlatforms];
          }

          return Object.keys(olv).map(key => ({
            name: key,
            restrictions: olv[key]
          }));
        };

        const convertAdditionalOLV = (
          olv: Record<string, Record<string, IPlatformConfig['restrictions']>>
        ) => {
          if (!olv) {
            return {};
          }

          const parsedOlv: Record<string, IPlatformConfig[]> = {};
          Object.keys(olv).forEach(taId => {
            parsedOlv[taId] = [];

            Object.entries(olv[taId]).forEach(([plId, pl]) => {
              parsedOlv[taId].push({
                name: plId,
                restrictions: pl
              });
            });
          });

          return parsedOlv;
        };

        const convertLengthShares = m => {
          const { allowedMediaStatus: mediaStatus } = this.props.profile;
          if (mediaStatus === 'internet') {
            return DEFAULT_LENGTH_SHARES;
          }

          if (m.lengthShares) {
            return m.lengthShares;
          }

          return [{ share: 100, length: m.movieDuration }];
        };

        const { allowedMediaStatus: mediaStatus } = this.props.profile;

        return {
          ...campaign,
          tv: {
            byChannel: convertByChannel(campaign.tv.byChannel),
            bySite: campaign.tv.bySite
          },
          isConverseOptimization: campaign.isConverseOptimization,
          olv: convertOLV(campaign.olv),
          additionalTaOlv: convertAdditionalOLV(campaign.additionalTaOlv),
          lengthShares: convertLengthShares(campaign),
          targetAudienceType: null,
          volumeSize: null,
          channel: campaign.channel && mediaStatus !== 'internet' ? campaign.channel : FEDERAL_CHANNEL,
          channelRestrictions: [],
          groupRestrictions: campaign.groupRestrictions || [],
          restrictionBase: campaign.restrictionBase || 'TRP_GRP',
          targetAudience: {
            ...campaign.targetAudience,
            displayedName: campaign.targetAudience.groupName
              ? `${campaign.targetAudience.groupName} / ${campaign.targetAudience.name}`
              : campaign.targetAudience.name
          },
          tvBudgetPercent:
            mediaStatus === 'all' ? campaign.tvBudgetPercent : mediaStatus === 'tv' ? 100 : 0,
          internetBudgetPercent:
            mediaStatus === 'all' ? campaign.internetBudgetPercent : mediaStatus === 'internet' ? 100 : 0,
          plots: campaign.plots
        };
      }
    );

    this.data = data;

    return {
      campaigns,
      type: data.type || 'MULTIMEDIA', // бэкенд это поле не сохраняет т.е. надо отправлять каждый раз
      form: {
        name: data.name,
        advertiser:
          advertisersOptions.find(
            advertiser => advertiser.value === data.advertiser.id
          ) || null,
        iterations:
          data.requestAlg && data.requestAlg.iterations
            ? data.requestAlg.iterations
            : 30000,
        year: moment(campaigns[0].from).year()
      }
    };
  }

  getBreadcrumbs (localizedTitle) {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='annualPlans' />,
        link: '/app/annualPlans'
      },
      {
        title: localizedTitle
      }
    ];
  }

  handleUpdateLengthShares = (lengthShares: ILengthShare[]) => {
    this.updateCampaignByID(this.state.selectedCampaignID!, { lengthShares });
    this.validateLengthShares(lengthShares);
  };

  validateLengthShares = (newLengthShares?: ILengthShare[]) => {
    const selectedCampaign = this.getSelectedCampaign();
    if (selectedCampaign) {
      const lengthShares = newLengthShares || selectedCampaign.lengthShares;
      const sumOfShares =
        lengthShares.reduce((acc, { share }) => acc + share * 10, 0) / 10;
      const isValidLengthShares = !(
        selectedCampaign.internetBudgetPercent !== 100 && sumOfShares !== 100
      );
      this.setState({ isValidLengthShares });
    }
  };

  handleUpdateGoal = (goal: IGoal) => {
    const type = goal.type;

    this.updateCampaignByID(this.state.selectedCampaignID!, {

      goals: [goal],
      isConverseOptimization: type === GoalType.Conversion

    });
  };

  validateFrequencies = () => {
    const selectedCampaign = this.getSelectedCampaign();
    const goal =
      selectedCampaign &&
      selectedCampaign.goals.length &&
      selectedCampaign.goals[0];

    if (goal && goal.reachFrequencyMin) {
      if (goal.reachFrequencyMax) {
        this.setState({
          targetReachIsOpen: false,
          isValidClosedFrequencies:
            goal.reachFrequencyMin.id <= goal.reachFrequencyMax.id
        });
      } else {
        this.setState({
          targetReachIsOpen: true
        });
      }
    }
  };

  handleFormChange = (name: keyof IMediaplanBaseParameters, value: any) => {
    if (name === 'year') {
      this.state.campaigns.forEach(c => {
        c.from = moment(c.from)
          .year(value)
          .format('YYYY-MM-DD');
        c.to = moment(c.to)
          .year(value)
          .format('YYYY-MM-DD');
      });

      this.loadSellerRestrictions();

      const selectedCampaign = this.getSelectedCampaign();
      if (selectedCampaign) {
        this.loadChannels(selectedCampaign);
      }
    }
    this.setState(prevState => ({
      form: { ...prevState.form, [name]: value }
    }));
  };

  handleUpdateAdvertiser = async (advertiser: IOption) => {
    const allBrandsData = await API.brands.listByAdvertiser(advertiser.value, {
      max: 0,
      agencyKeycloakId: this.props.profile.extend.agency.id
    });

    this.setState({
      allBrands: allBrandsData.items
    });
  };

  periodMonthShare = (
    from: string | undefined,
    to: string | undefined,
    year: number,
    month: number
  ) => {
    if (!from || !to) {
      return 0;
    }

    const fromDayStart = moment(from).startOf('day');
    const toDayEnd = moment(to).endOf('day');
    const period = moment.range(fromDayStart, toDayEnd);
    const date = new Date();

    date.setFullYear(year, month, 1);

    const intersect = moment
      .range(moment(date).startOf('month'), moment(date).endOf('month'))
      .intersect(period);

    return intersect ? intersect.valueOf() / period.valueOf() : 0;
  };

  recalculateBudgetChartData = () => {
    let annualBudget = 0;
    const selectedTargetAudiences: string[] = [];
    const brands: IBrand[] = [];
    const mediaplanBrands = this.state.campaigns.map(c => c.brand);

    mediaplanBrands.forEach(b => {
      if (b && brands.find(br => br.id === b.id) === undefined) {
        brands.push(b);
      }
    });

    const budgetsByBrand: number[][] = [];
    brands.forEach(() => {
      budgetsByBrand.push(new Array(12).fill(0));
    });

    const brandsBudget: IBrandBudget[] = [];

    this.state.campaigns.forEach(m => {
      annualBudget += m.budget ? m.budget : 0;

      if (m.targetAudience) {
        selectedTargetAudiences.push(m.targetAudience.name);
      }

      new Array(12).fill(null).forEach((_, month) => {
        const dateShare = this.periodMonthShare(
          m.from,
          m.to,
          this.state.form.year,
          month
        );
        const budgetShare = dateShare * (m.budget || 0);
        const brand = m.brand;

        if (brand) {
          budgetsByBrand[brands.findIndex(b => b.id === brand.id)][
            month
          ] += budgetShare;
          let brandBudget = brandsBudget.find(b => b.brand.id === brand.id);

          if (!brandBudget) {
            brandBudget = {
              brand,
              budget: new Array(12).fill(0)
            };

            brandsBudget.push(brandBudget);
          }

          brandBudget.budget[month] += budgetShare;
        }
      });
    });

    for (let j = 0; j < budgetsByBrand.length; j++) {
      budgetsByBrand[j] = budgetsByBrand[j].map(b => Math.round(b));
    }

    brandsBudget.forEach(b => {
      b.budget = b.budget.map(budget => Math.round(budget));
    });

    this.setState({
      annualBudget,
      selectedTargetAudiences: selectedTargetAudiences.join(', '),
      monthsBudgetBarChartData: budgetsByBrand,
      monthsBudgetBarChartSeries: brands.map(brand => brand.name),
      brandsBudget
    });
  };

  selectCampaign = (campaign: ICampaign) => {
    this.setState(
      {
        selectedCampaignID: campaign.id,
        isValidClosedFrequencies: true
      },
      () => {
        this.validateLengthShares();
        this.validateFrequencies();
      }
    );
  };

  handleRemoveCampaign = (campaignID: string | number) => {
    this.setState(
      prevState => ({
        ...prevState,
        campaigns: prevState.campaigns.filter(c => c.id !== campaignID)
      }),
      this.recalculateBudgetChartData
    );

    const { campaigns } = this.state;

    if (campaigns.length) {
      this.selectCampaign(campaigns[0]);
    }
  };

  getYearForSelectedCampaign = () => {
    let date = moment();
    const selectedCampaign = this.getSelectedCampaign();

    if (selectedCampaign && selectedCampaign.from) {
      date = moment(selectedCampaign.from);
    }

    return date.year();
  };

  handleChangeIsOldRestrictions = (isOldTvRestrictions: boolean) => {
    this.setState({ isOldTvRestrictions });
  };

  selectChannelSource = makeSelectChannelSource();

  getInitialTV = (
    channel: ICampaign['channel']
  ): ICampaign['tv']['byChannel'] => {
    const { allChannels } = this.state;
    const source = this.selectChannelSource(channel, allChannels);

    return source.reduce((acc, channel) => {
      const newChannelConfig: IChannelConfig = {
        restrictions: { isExcludedChannel: true },
        extraCharge: {},
        cpp: {
          advertiserBasePrice: null,
          additionalDiscount: null
        }
      };

      return { ...acc, [channel.id]: newChannelConfig };
    }, {});
  };

  cancelEditAnnualPlan = () => {
    const { history } = this.props;

    if (this.data) {
      if (this.data.code && this.data.status.name === 'COMPLETE') {
        history.push(`/app/annualPlans/${this.data.code}`);
      } else if (this.data.code && this.data.status.name !== 'COMPLETE') {
        history.push('/app/annualPlans');
      } else {
        history.push('/app');
      }
    } else {
      history.push('/app');
    }
  };

  getFormattedPlanForSave = () => {
    const {
      type,
      form: { advertiser, name, iterations, year },
      allChannels,
      isCalculatePalomarsReach,
      palomarsRetroDates: retroDates,
      customUniverse,
      isTradeWhiteList,
      isTradeBusied,
      isAdvertiserCounted,
      performanceCalculationType
    } = this.state;

    const agencyKeycloakId = this.props.profile.extend.agency.id;

    const campaignPromises = this.state.campaigns.map(m => loadTvPricesByChannelAndDistributionAndMonths(
      moment(m.from).year(),
      false
    ).then(tvTvPricesByChannelDistributionAndMonths => {
      const filteredCampaign: ICampaign & any = cloneObject(m);

      if (
        filteredCampaign.plots &&
          filteredCampaign.plots.cbuPlot?.enable &&
          filteredCampaign.plots.cbuPlot?.freqs?.length === 0
      ) {
        filteredCampaign.plots.cbuPlot.enable = false;
      }

      const isTvOlvPossible =
          filteredCampaign.internetBudgetPercent !== 100 &&
          filteredCampaign.tvBudgetPercent !== 100;
      if (
        filteredCampaign.plots &&
          filteredCampaign.plots.tvOlvPlot?.enable &&
          (!isTvOlvPossible ||
            filteredCampaign.plots.tvOlvPlot?.freqs?.length === 0)
      ) {
        filteredCampaign.plots.tvOlvPlot.enable = false;
      }

      if (typeof filteredCampaign.id === 'string') {
        delete filteredCampaign.id;
      }

      if (filteredCampaign.channel.value === 'federal-channels') {
        delete filteredCampaign.channel; // TODO: remove it
      }

      if (filteredCampaign.targetAudience) {
        delete filteredCampaign.targetAudience.displayedName;
      }

      if (m.tvBudgetPercent === 100) {
        delete filteredCampaign.olv;
        delete filteredCampaign.additionalTaOlv;
      } else {
        const filteredPlatforms = filteredCampaign.olv
          .filter(this.olvPlatformIsNotEmpty)
          .filter(
            platform => !property('restrictions.isExcludePlatform')(platform)
          );

        // @ts-ignore
        filteredCampaign.olv = filteredPlatforms.length
          ? filteredPlatforms.reduce(
            (acc, item) => ({ ...acc, [item.name]: item.restrictions }),
            {}
          )
          : null;

        const additionalTaOlv: Record<string, Record<string, any>> = {};

        Object.entries(filteredCampaign.additionalTaOlv).forEach(
          ([taId, taPlatforms]: [string, IPlatformConfig[]]) => {
            const additionalTaPlatforms = taPlatforms
              .filter(this.olvPlatformIsNotEmpty)
              .filter(
                platform => !property('restrictions.isExcludePlatform')(platform)
              );
            additionalTaOlv[taId] = {};

            additionalTaPlatforms.forEach(platform => {
              additionalTaOlv[taId][platform.name] = platform.restrictions;
            });
          }
        );
        filteredCampaign.additionalTaOlv = additionalTaOlv;
      }

      const updatedCampaignChannels = this.selectChannelSource(
        m.channel,
        allChannels
      );
      updatedCampaignChannels.forEach(channel => {
        restrictionKeys.forEach(fieldName => {
          if (
            typeof filteredCampaign.tv.byChannel[channel.id].restrictions[
              fieldName
            ] !== 'undefined'
          ) {
            const newMainFieldName = fieldName.slice(0, -3);
            const newFieldPostfixName =
                fieldName.slice(-3) === 'Min' ? 'min' : 'max';

            if (
              !filteredCampaign.tv.byChannel[channel.id].restrictions[
                newMainFieldName
              ]
            ) {
              filteredCampaign.tv.byChannel[channel.id].restrictions[
                newMainFieldName
              ] = {};
            }

            filteredCampaign.tv.byChannel[channel.id].restrictions[
              newMainFieldName
            ][newFieldPostfixName] =
                filteredCampaign.tv.byChannel[channel.id].restrictions[
                  fieldName
                ];
            delete filteredCampaign.tv.byChannel[channel.id].restrictions[
              fieldName
            ];
          }
        });
      });

      filteredCampaign.tvPrices = getTvPrices(
        tvTvPricesByChannelDistributionAndMonths,
        updatedCampaignChannels,
        filteredCampaign.tv.byChannel
      );
      filteredCampaign.webPrices = null;

      filteredCampaign.groupRestrictions = filteredCampaign.groupRestrictions.filter(
        group => group.channels.length
      );

      filteredCampaign.additionalTA = (
        filteredCampaign.additionalTA || []
      ).map(a => ({ id: a.id, name: a.name }));

      return {
        id: filteredCampaign.id || null,
        externalId: m.externalId || null,
        dataJson: JSON.stringify(filteredCampaign)
      };
    })
    );
    let currentType = type;

    return Promise.all(campaignPromises).then(campaigns => {
      const filteredAnnualPlan = this.data
        ? cloneObject(this.data)
        : { version: 1 };
      // @ts-ignore
      delete filteredAnnualPlan.requestAlg;
      // @ts-ignore
      filteredAnnualPlan.annualPeriod = year;
      const campaignsWithRegions = campaigns.map(({ dataJson, ...other }, index) => {
        const parsedData = JSON.parse(dataJson);
        if (parsedData.tvBudgetPercent === 100) {
          currentType = 'TV';
        }

        if (parsedData.internetBudgetPercent === 100) {
          currentType = 'WEB';
        }

        return {
          ...other,
          dataJson: JSON.stringify({
            ...parsedData,
            region: this.state.campaigns[index].channel.label,
          })
        };
      });

      const palomarsRetroDates = !isCalculatePalomarsReach
        ? {}
        : retroDates && (retroDates.startDate || retroDates.endDate)
          ? {
            palomarsRetroFrom:
              retroDates.startDate &&
              moment(retroDates.startDate).format('YYYY-MM-DD'),
            palomarsRetroTo:
              retroDates.endDate &&
              moment(retroDates.endDate).format('YYYY-MM-DD')
          }
          : {
            palomarsRetroFrom: this.state.campaigns[0].retroFrom,
            palomarsRetroTo: this.state.campaigns[0].retroTo
          };

      return {
        advertiserKeycloakId: advertiser!.value,
        agencyKeycloakId,
        name,
        type: currentType,
        version: filteredAnnualPlan.version,
        annualPeriod: year,
        isTradeWhiteList,
        isTradeBusied,
        isAdvertiserCounted,
        performanceCalculationType,
        requestAlg: { iterations },
        dataJson: JSON.stringify(filteredAnnualPlan),
        campaigns: campaignsWithRegions,
        needCalculate: true,
        isCalculatePalomarsReach,
        ...palomarsRetroDates,
        isCustomUniverse: customUniverse.isEnabled || false,
        customUniverseValue: customUniverse.isEnabled ? customUniverse.value : undefined,
      };
    });
  };

  savePlatforms = async () => {
    try {
      const platformNames = flatten(
        this.state.campaigns.map(m => m.olv
          .filter(
            platform => !property('restrictions.isExcludePlatform')(platform)
          )
          .map(platform => platform.name)
        )
      );

      const requests = platformNames.map(name => ({
        name,
        platformType: 'OLV'
      }));
      await Promise.all(requests.map(req => API.adPlatforms.create(req)));
    } catch (e) {
      //  todo add Alert.error(errorsFormattedText);
    }
  };

  saveWithoutCalculations = async () => {
    const { history } = this.props;

    const code = (this.data && this.data.code) || null;

    const formattedPlanForSave = await this.getFormattedPlanForSave();
    await API.annualPlans.saveWithoutCalculations({
      ...formattedPlanForSave,
      code
    });

    history.push('/app/annualPlans');
  };

  saveAnnualPlan = async () => {
    const { match, history } = this.props;
    const { name } = this.state.form;

    const code = (this.data && this.data.code) || null;

    try {
      const formattedPlanForSave = await this.getFormattedPlanForSave();
      const response = await API.annualPlans.save(code, formattedPlanForSave);

      if (response && response.code !== 0) {
        // error
        Alert.error(
          `${localizeMessage(
            { id: 'annualPlanEdit.errors.failedCalculation' },
            { name }
          )}. ${localizeMessage({ id: 'annualPlanEdit.errors.errorCode' })}: ${
            response.code
          }, ${localizeMessage({
            id: 'annualPlanEdit.errors.errorMessage'
          })}: ${response.message}`
        );

        history.push('/app/annualPlans');
      } else {
        if (match!.params.id) {
          Alert.success(
            localizeMessage(
              { id: 'annualPlanEdit.alerts.mediaplanUpdated' },
              { name }
            )
          );
        } else {
          Alert.success(
            localizeMessage(
              { id: 'annualPlanEdit.alerts.mediaplanSaved' },
              { name }
            )
          );
        }
        if (response && response.code === 0) {
          // success
          Alert.success(
            localizeMessage(
              { id: 'annualPlanEdit.alerts.mediaplanSchedules' },
              { name }
            )
          );
        }

        history.push('/app/annualPlans');
      }
    } catch (error) {
      console.error(error);

      if (error.response.status === 422 && error.response.data.errors) {
        let errorsFormattedText = localizeMessage(
          { id: 'annualPlanEdit.errors.errorSaving' },
          { name }
        );

        if (
          error.response.data.errors.common &&
          error.response.data.errors.common.length > 0
        ) {
          error.response.data.errors.common.forEach(ce => {
            errorsFormattedText += `\n${ce}`;
          });
        }

        if (
          error.response.data.errors.properties &&
          !error.response.data.errors.properties.length
        ) {
          error.response.data.errors.properties.forEach(value => {
            errorsFormattedText += `\n${value}`;
          });
        }

        Alert.error(errorsFormattedText);
      }

      history.push('/app/annualPlans');
    }
  };

  checkErrorsForTV = (campaign: ICampaign) => {
    const errors: string[] = [];
    const campaignID = campaign.id;

    if (campaign.internetBudgetPercent === 100) {
      return [];
    }

    if (
      campaign.internetBudgetPercent < 100 &&
      Object.values(campaign.tv.byChannel).every(
        r => r.restrictions.isExcludedChannel
      )
    ) {
      errors.push(
        localizeMessage({
          id: 'annualPlanEdit.errors.channelIsMissingForCampaign'
        })
      );
    }
    const campaignTvIsValid = this.mapCampaignsToTvIsValid[campaignID];

    if (campaignTvIsValid) {
      if (campaignTvIsValid.restrictions === false) {
        errors.push(
          localizeMessage({
            id: 'annualPlanEdit.errors.invalidChannelRestrictions'
          })
        );
      }
      if (campaignTvIsValid.cpp === false) {
        errors.push(
          localizeMessage({ id: 'annualPlanEdit.errors.invalidChannelCPP' })
        );
      }
      if (campaignTvIsValid.discounts === false) {
        errors.push(
          localizeMessage({
            id: 'annualPlanEdit.errors.invalidChannelDiscounts'
          })
        );
      }
      if (campaignTvIsValid.extraCharge === false) {
        errors.push(
          localizeMessage({ id: 'annualPlanEdit.errors.invalidChannelExtraCharge' })
        );
      }
    }
    if (this.mapCampaignsChannelGroupsIsValid[campaignID] === false) {
      errors.push(
        localizeMessage({ id: 'annualPlanEdit.errors.invalidChannelGroups' })
      );
    }

    return errors;
  };

  checkErrorsForOLV = (campaign: ICampaign) => {
    const errors: string[] = [];
    const campaignID = campaign.id;

    if (campaign.tvBudgetPercent === 100) {
      return [];
    }

    if (
      campaign.internetBudgetPercent > 0 &&
      campaign.olv.every(r => r.restrictions.isExcludePlatform)
    ) {
      errors.push(
        localizeMessage({
          id: 'annualPlanEdit.errors.platformIsMissingForCampaign'
        })
      );
    }

    const mapTabToIsvalid = this.mapCampaignsToOLVTabsValidation[campaignID];
    if (mapTabToIsvalid?.restrictions === false) {
      errors.push(
        localizeMessage({ id: 'annualPlanEdit.errors.invalidOLVRestrictions' })
      );
    }
    if (mapTabToIsvalid?.discounts === false) {
      errors.push(
        localizeMessage({ id: 'annualPlanEdit.errors.invalidOLVDiscounts' })
      );
    }

    const olv = concat(campaign.olv, Object.values(campaign.additionalTaOlv).flat());
    if (
      mapTabToIsvalid?.cpm === false ||
      olv.some(({ restrictions: r }) => !r.isExcludePlatform &&
        (r.advertiserBasePrice === undefined || isNaN(r.advertiserBasePrice)))
    ) {
      errors.push(
        localizeMessage({ id: 'annualPlanEdit.errors.invalidOLVCpm' })
      );
    }

    return errors;
  };

  olvPlatformIsNotEmpty = (platform: IPlatformConfig) => platformNullableProps.some(prop => {
    const value = property(prop)(platform);

    return value !== undefined && value !== null;
  });

  checkErrors = () => {
    const {
      campaigns,
      palomarsRetroDates,
      isCalculatePalomarsReach,
      customUniverse,
      form: { name, advertiser }
    } = this.state;

    let errors: string[] = [];

    if (isCalculatePalomarsReach) {
      if (palomarsRetroDates.endDate === null) {
        errors.push(
          localizeMessage({
            id: 'annualPlanEdit.errors.invalidPalomarsRetroDateEnd'
          })
        );
      } else if (palomarsRetroDates.startDate === null) {
        errors.push(
          localizeMessage({
            id: 'annualPlanEdit.errors.invalidPalomarsRetroDateStart'
          })
        );
      }
    }

    if (customUniverse.isEnabled && !customUniverse.value) {
      errors.push(
        localizeMessage({
          id: 'annualPlanEdit.errors.customUniverseValueNotExceed0'
        })
      );
    }

    if (!name || !name.trim().length) {
      errors.push(
        localizeMessage({ id: 'annualPlanEdit.errors.mediaplanNameIsMissing' })
      );
    }

    if (!advertiser) {
      errors.push(
        localizeMessage({
          id: 'annualPlanEdit.errors.mediaplanAdvertiserIsMissing'
        })
      );
    }

    if (campaigns && campaigns.length) {
      if (this.hasEditableCampaigns) {
        errors.push(
          localizeMessage({
            id: 'annualPlanEdit.errors.someCampaignAreInEditMode'
          })
        );
      } else {
        if (campaigns.some(m => !m.name || !m.name.trim().length)) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.nameIsMissingForCampaign'
            })
          );
        }
        if (campaigns.some(m => !m.from || !m.to)) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.periodIsMissingForCampaign'
            })
          );
        }
        if (campaigns.some(m => !m.retroFrom || !m.retroTo)) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.retroPeriodIsMissingForCampaign'
            })
          );
        }
        if (campaigns.some(m => !m.budget)) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.budgetIsMissingForCampaign'
            })
          );
        }
        if (
          campaigns.some(m => {
            const sumOfShares = m.lengthShares.reduce(
              (acc, { share }) => acc + share,
              0
            );

            return sumOfShares !== 100;
          })
        ) {
          errors.push(
            localizeMessage({ id: 'annualPlanEdit.errors.sumOfShares' })
          );
        }
        if (
          campaigns.some(m => {
            const reachShare = m.goals[0].goalReachShare;
            const reachType = m.goals[0].type;

            return (
              reachType === GoalType.Reach &&
              (reachShare === null ||
                reachShare === undefined ||
                reachShare > 100 ||
                reachShare < 0)
            );
          })
        ) {
          errors.push(
            localizeMessage({ id: 'annualPlanEdit.errors.invalidReachShare' })
          );
        }
        if (
          campaigns.some(m => {
            const reachShare = m.goals[0].goalReachShare;
            const reachType = m.goals[0].type;

            return (
              reachType === GoalType.Conversion &&
              (reachShare === null ||
                reachShare === undefined ||
                reachShare > 100 ||
                reachShare < 0)
            );
          })
        ) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.invalidMinReachShare'
            })
          );
        }
        if (
          campaigns.some(m => {
            const reachFrequencyMin =
              m.goals[0].reachFrequencyMin && m.goals[0].reachFrequencyMin.id;
            const reachFrequencyMax =
              m.goals[0].reachFrequencyMax && m.goals[0].reachFrequencyMax.id;

            return (
              reachFrequencyMax && Number(reachFrequencyMin) > reachFrequencyMax
            );
          })
        ) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.invalidFrequencyRange'
            })
          );
        }
        if (
          campaigns.some(campaign => {
            const noPlatforms = campaign.olv.every(
              r => r.restrictions.isExcludePlatform
            );
            const noChannels = Object.values(campaign.tv.byChannel).every(
              (r: any) => r.restrictions.isExcludedChannel
            );

            return (
              campaign.internetBudgetPercent === 0 &&
              campaign.tvBudgetPercent === 0 &&
              noPlatforms &&
              noChannels
            );
          })
        ) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.platformIsMissingForCampaign'
            })
          );
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.channelIsMissingForCampaign'
            })
          );
        }

        if (campaigns.some(campaign => !isCbuValid(campaign.plots?.cbuPlot))) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.badCbuPlotSettings'
            })
          );
        }

        if (
          campaigns.some(campaign => !isTvOlvValid(campaign.plots?.tvOlvPlot))
        ) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.badTvOlvPlotSettings'
            })
          );
        }

        if (
          campaigns.some(
            campaign => campaign.plots?.tvOlvPlot?.enable &&
              (campaign.tvBudgetPercent === 100 ||
                campaign.internetBudgetPercent === 100)
          )
        ) {
          errors.push(
            localizeMessage({
              id: 'annualPlanEdit.errors.tvOlvNotPossible'
            })
          );
        }

        campaigns.forEach(m => {
          const errorsForTV = this.checkErrorsForTV(m);
          const errorsForOLV = this.checkErrorsForOLV(m);
          errors = [...errors, ...errorsForTV, ...errorsForOLV];
        });
      }
    } else {
      errors.push(
        localizeMessage({ id: 'annualPlanEdit.errors.noOneSpecifiedCampaign' })
      );
    }

    return uniq(errors);
  };

  saveNoCalculate = () => {
    const errors = this.checkErrors();

    if (errors.length) {
      this.setState({
        isShowErrors: true
      });

      SweetAlert({
        icon: 'error',
        title: localizeMessage({
          id: 'annualPlanEdit.errors.validationErrors'
        }),
        content: (
          <div>
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )
      });
    } else {
      this.savePlatforms();
      this.saveWithoutCalculations();
    }
  };

  saveCalculate = () => {
    const errors = this.checkErrors();

    if (errors.length) {
      this.setState({
        isShowErrors: true
      });

      SweetAlert({
        icon: 'error',
        title: localizeMessage({
          id: 'annualPlanEdit.errors.validationErrors'
        }),
        content: (
          <div>
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )
      });
    } else {
      this.savePlatforms();
      this.saveAnnualPlan();
    }
  };

  setDefaultPalomarsRetroDates () {
    this.setState(state => ({
      palomarsRetroDates: {
        startDate:
          state.campaigns[0] && state.campaigns[0].retroFrom
            ? moment(state.campaigns[0].retroFrom)
            : null,
        endDate:
          state.campaigns[0] && state.campaigns[0].retroTo
            ? moment(state.campaigns[0].retroTo)
            : null
      }
    }));
  }

  handleUpdateGroupsValidationStatus = (
    campaignID: number,
    isValid: boolean
  ) => {
    this.mapCampaignsChannelGroupsIsValid = {
      ...this.mapCampaignsChannelGroupsIsValid,
      [campaignID]: isValid
    };
  };

  handleUpdateTvValidationStatus = (
    campaignID: number,
    isValid: boolean,
    tab: string
  ) => {
    const prevValue = this.mapCampaignsToTvIsValid[campaignID];
    this.mapCampaignsToTvIsValid[campaignID] = prevValue
      ? { ...prevValue, [tab]: isValid }
      : { [tab]: isValid };
  };

  handleUpdateOLVValidationStatus = (
    campaignID: number,
    isValid: boolean,
    tab: string
  ) => {
    const prevValue = this.mapCampaignsToOLVTabsValidation[campaignID];
    this.mapCampaignsToOLVTabsValidation[campaignID] = prevValue
      ? { ...prevValue, [tab]: isValid }
      : { [tab]: isValid };
  };

  handleUpdatePlatforms = (
    campaignID: string | number,
    updatedPlatforms: IPlatformConfig[]
  ) => {
    this.updateCampaignByID(campaignID, { olv: updatedPlatforms });

    // assign min, max & exclude for additional
  };

  updateCampaignPlots = (
    campaignID: string | number,
    plots: Partial<ICampaignPlots>
  ) => {
    this.setState(prevProps => ({
      ...prevProps,
      campaigns: prevProps.campaigns.map(campaign => (campaign.id === campaignID
        ? {
          ...campaign,
          plots: {
            ...(campaign.plots || {}),
            ...plots
          }
        }
        : campaign)
      )
    }));
  };

  updateCampaignByID = (
    campaignID: string | number,
    update: Partial<ICampaign>
  ) => {
    this.setState(
      prevProps => ({
        ...prevProps,
        campaigns: prevProps.campaigns.map(
          campaign => (campaign.id === campaignID ? { ...campaign, ...update } : campaign)
        )
      }),
      update.goals && this.validateFrequencies
    );
  };

  handleEditCampaign = (
    campaignID: string | number,
    update: Partial<ICampaign>
  ) => {
    this.setState(
      prevProps => ({
        ...prevProps,
        campaigns: prevProps.campaigns.map(campaign => {
          if (campaign.id === campaignID) {
            if (
              update.channel &&
              update.channel.value !== campaign.channel.value
            ) {
              return {
                ...campaign,
                ...update,
                tv: { byChannel: this.getInitialTV(update.channel) }
              };
            }

            return { ...campaign, ...update };
          }

          return campaign;
        })
      }),
      this.recalculateBudgetChartData
    );
  };

  handleAddNewCampaign = (
    campaignID: string | number,
    update: CampaignUpdate
  ) => {
    const { allFrequencies } = this.state;
    const { allowedMediaStatus: mediaStatus } = this.props.profile;
    const newCampaign: ICampaign = {
      id: campaignID,
      ...update,

      additionalTaOlv: {},

      restrictionBase: 'TRP_GRP',
      groupRestrictions: [],
      lengthShares: mediaStatus === 'internet' ? DEFAULT_LENGTH_SHARES : [],
      tv: { byChannel: this.getInitialTV(update.channel) },
      olv: [...this.defaultPlatforms],
      goals: [
        {
          type: GoalType.Budget,
          reachFrequencyMin: {
            id: allFrequencies[0].value,
            name: allFrequencies[0].name
          },
          reachFrequencyMax: null
        }
      ]
    };

    this.setState(
      prevProps => ({
        ...prevProps,
        campaigns: [...prevProps.campaigns, newCampaign],
        targetReachIsOpen: true
      }),
      () => {
        this.selectedCampaignAndLoadChannels(newCampaign);
        this.recalculateBudgetChartData();
      }
    );
  };

  getCampaigns = (): ICampaign[] => this.state.campaigns;

  getSelectedCampaign = (): ICampaign | null => {
    const { selectedCampaignID, campaigns } = this.state;
    const selectedCampaign = campaigns.find(c => c.id === selectedCampaignID);

    return selectedCampaign || null;
  };

  handleSelectCampaign = (campaignID: string | number) => {
    const newSelectedCampaign = this.state.campaigns.find(
      c => c.id === campaignID
    );
    if (newSelectedCampaign) {
      this.selectedCampaignAndLoadChannels(newSelectedCampaign);
    }
  };

  handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    this.setState({
      isCalculatePalomarsReach: value
    });
  };

  handleUpdatePalomarsRetroDates = (dates: INullableMomentRange) => {
    this.setState({
      palomarsRetroDates: dates
    });
    this.checkRetroperiodRange(dates);
  };

  handleCustomUniverseChange: HandleCustomUniverseChange = (fieldName, value) => {
    if (fieldName === 'isEnabled') {
      this.setState(state => ({
        customUniverse: { ...state.customUniverse, isEnabled: value },
      }));
      if (value) {
        Alert.warning(
          localizeMessage({
            id: 'annualPlanEdit.campaignsTable.warnings.customUniverseIsEnabled'
          })
        );
      }
    }
    if (fieldName === 'value') {
      this.setState(state => ({
        customUniverse: { ...state.customUniverse, value: Number(value) },
      }));
    }
  };

  selectedCampaignAndLoadChannels = async (campaign: ICampaign) => {
    const selectedCampaign = this.getSelectedCampaign();

    if (selectedCampaign === null || selectedCampaign.id !== campaign.id) {
      await this.loadChannels(campaign);
      this.selectCampaign(campaign);
    }
  };

  checkRetroperiodRange (range: INullableMomentRange) {
    if (
      range.startDate &&
      range.endDate &&
      range.endDate.diff(range.startDate, 'months') > 1
    ) {
      Alert.warning(
        localizeMessage({
          id: 'annualPlanEdit.campaignsTable.warnings.retroperiodIsTooLarge'
        })
      );
    }
  }

  render () {
    const { match, profile } = this.props;
    const newAnnualPlanType = match!.params.type;
    const {
      status,
      form,
      form: { advertiser, year },
      advertisersOptions,
      allFrequencies,
      allClosedFrequencies,
      isShowErrors,
      selectedTargetAudiences,
      annualBudget,
      retroDiffs,

      allChannelTypes,
      allBrands,
      allTargetAudiences,
      allChannels,
      regionsOptions,
      isOldTvRestrictions,
      isValidLengthShares,
      isValidClosedFrequencies,
      targetReachIsOpen,

      monthsBudgetBarChartData,
      monthsBudgetBarChartSeries,
      brandsBudget,

      isLoadingPrices,
      selectedCampaignID,
      mapCampaignToTargetAudienceTypes,
      campaigns,
      sellerRestrictions,
      isCalculatePalomarsReach,
      palomarsRetroDates,
      customUniverse,

      isTradeWhiteList,
      performanceCalculationType
    } = this.state;
    // const isLoadingTaVolume = campaigns.some(m => {
    //   const TaType = mapCampaignToTargetAudienceTypes[m.id];
    //
    //   return !(TaType && TaType.type);
    // });
    const isLoadingTaVolume = false;

    const isCalculating = status === 'IN_PROGRESS';

    const selectedCampaign = this.getSelectedCampaign();

    if (profile.allowedMediaStatus === 'none') {
      return null;
    }

    return (
      <>
        <LocalizedMessage
          id={['site.title.annualPlanEdit', 'site.title.annualPlanEdit.new']}
        >
          {(localizedEditAnnualPlanMessage, localizedNewAnnualPlanMessage) => (
            <>
              <Helmet
                title={
                  newAnnualPlanType
                    ? localizedNewAnnualPlanMessage
                    : !this.data
                      ? localizedEditAnnualPlanMessage
                      : this.data.name
                }
              />
              <Breadcrumbs
                className='col-lg-4'
                title={
                  newAnnualPlanType
                    ? localizedNewAnnualPlanMessage
                    : !this.data
                      ? localizedEditAnnualPlanMessage
                      : this.data.name
                }
                data={this.getBreadcrumbs(
                  newAnnualPlanType
                    ? localizedNewAnnualPlanMessage
                    : localizedEditAnnualPlanMessage
                )}
              >
                <div className='col-lg-8'>
                  <div className='title-action'>
                    <Link
                      className='btn btn-white'
                      to='/app'
                      data-test='cancel'
                    >
                      <i className={cx('fa', 'fa-arrow-left', 'fa-lg', classes.cancelBtn)} />
                      <LocalizedMessage id='cancel' />
                    </Link>
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='wrapper wrapper-content'>
              <div className='ibox'>
                <div className='ibox-title'>
                  <h5>
                    <LocalizedMessage id='annualPlanEdit.basePlanningParameters' />
                  </h5>
                </div>
                <div className='ibox-content'>
                  <BaseParameters
                    isShowErrors={isShowErrors}
                    form={form}
                    advertisersOptions={advertisersOptions}
                    onUpdateAdvertiser={this.handleUpdateAdvertiser}
                    onUpdateForm={this.handleFormChange}
                  />
                </div>
              </div>

              {advertiser && (
                <div className='ibox'>
                  <div className='ibox-title'>
                    <h5>
                      <LocalizedMessage id='campaigns' />
                    </h5>
                  </div>
                  <div className='ibox-content'>
                    <CampaignsTable
                      selectedCampaignID={selectedCampaignID}
                      getCampaigns={this.getCampaigns}
                      retroDiffs={retroDiffs}
                      regionsOptions={regionsOptions}
                      brands={allBrands}
                      targetAudiences={allTargetAudiences}
                      year={Number(year)}
                      allowedMediaStatus={profile.allowedMediaStatus}
                      mapCampaignToTargetAudienceTypes={
                        mapCampaignToTargetAudienceTypes
                      }
                      onSelectCampaign={this.handleSelectCampaign}
                      onUpdateHasEditableCampaigns={
                        this.handleUpdateHasEditableCampaigns
                      }
                      onUpdateAudienceType={this.loadTargetAudienceType}
                      onUpdateAudienceList={this.updateTargetAudienceList}
                      onAddNewCampaign={this.handleAddNewCampaign}
                      onEditCampaign={this.handleEditCampaign}
                      onRemoveCampaign={this.handleRemoveCampaign}
                      onCheckRetroperiodRange={this.checkRetroperiodRange}
                    />

                    <div className='hr-line-dashed' />

                    {campaigns.length ? (
                      <div className='form-horizontal'>
                        <div className='row'>
                          <div className='row m-b-sm'>
                            <div className='col-xs-1' />
                            <div className='col-xs-3 text-right'>
                              <label>
                                <LocalizedMessage id='totalBudgetRur' />
                              </label>
                            </div>
                            <div className='col-xs-8'>
                              {formattedNumberWithZero(annualBudget)}
                            </div>
                          </div>
                          <div className='row m-b-sm'>
                            <div className='col-xs-1' />
                            <div className='col-xs-3 text-right'>
                              <label>
                                <LocalizedMessage id='targetAudiences' />
                              </label>
                            </div>
                            <div className='col-xs-8'>
                              {selectedTargetAudiences}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              {selectedCampaign &&
                monthsBudgetBarChartData &&
                monthsBudgetBarChartData.length > 0 && (
                  <div className='ibox'>
                    <div className='ibox-title'>
                      <h5>
                        <LocalizedMessage id='annualPlanEdit.calculatedValues' />
                        {' '}
                        {year}
                      </h5>
                    </div>
                    <div className='ibox-content'>
                      <CalculatedValues
                        monthsBudgetBarChartData={monthsBudgetBarChartData}
                        monthsBudgetBarChartSeries={monthsBudgetBarChartSeries}
                        brandsBudget={brandsBudget}
                      />
                    </div>
                  </div>
              )}
              {selectedCampaign && (
                <div className='ibox'>
                  <div className='ibox-title'>
                    <h5>
                      <LocalizedMessage id='annualPlanEdit.campaignGoals' />
                    </h5>
                  </div>
                  <div className='ibox-content'>
                    <CampaignGoals
                      isInternetOnlyAllowed={profile.allowedMediaStatus === 'internet'}
                      isConverseOptimization={
                        selectedCampaign.isConverseOptimization
                      }
                      onChangeIsConverseOptimization={
                        (newValue: boolean) => this.updateCampaignByID(selectedCampaignID!!, {
                          isConverseOptimization: newValue
                        })
                      }
                      onChangeIsTradeWhiteList={(newValue: boolean) => {
                        this.setState({ isTradeWhiteList: newValue });
                      }}
                      isTradeWhiteList={isTradeWhiteList}
                      performanceCalculationType={performanceCalculationType}
                      onChangePerformanceCalculationType={(
                        newValue: IPerformanceCalculationType
                      ) => {
                        this.setState({ performanceCalculationType: newValue });
                      }}
                      goal={selectedCampaign.goals[0]}
                      lengthShares={selectedCampaign.lengthShares}
                      allFrequencies={allFrequencies}
                      allClosedFrequencies={allClosedFrequencies}
                      onUpdateLengthShares={this.handleUpdateLengthShares}
                      onUpdateGoal={this.handleUpdateGoal}
                      isValidClosedFrequencies={isValidClosedFrequencies}
                      targetReachIsOpen={targetReachIsOpen}
                      isCalculatePalomarsReach={isCalculatePalomarsReach}
                      palomarsRetroDates={palomarsRetroDates}
                      onChangeCalculatePalomars={this.handleCheckboxChange}
                      onUpdatePalomarsRetroDates={
                        this.handleUpdatePalomarsRetroDates
                      }
                      customUniverse={customUniverse}
                      onCustomUniverseChange={this.handleCustomUniverseChange}
                    />
                  </div>
                </div>
              )}

              {selectedCampaign && (
                <div className='ibox'>
                  <div className='ibox-title'>
                    <h5>
                      <LocalizedMessage id='annualPlanEdit.campaignCharts' />
                    </h5>
                  </div>
                  <div className='ibox-content'>
                    <Charts
                      campaignID={selectedCampaign.id}
                      plots={selectedCampaign.plots}
                      onUpdateCbu={(id, values) => this.updateCampaignPlots(id, { cbuPlot: values })}
                      onUpdateTvOlv={(id, values) => this.updateCampaignPlots(id, { tvOlvPlot: values })}
                    />
                  </div>
                </div>
              )}
              {isValidLengthShares &&
              isValidClosedFrequencies &&
              advertiser &&
              selectedCampaign &&
              Object.keys(selectedCampaign.tv.byChannel).length ? (
                <div
                  className={cx('ibox', {
                    'ibox-invisible': profile.allowedMediaStatus === 'internet',
                    'ibox-disabled':
                      selectedCampaign.internetBudgetPercent === 100
                  })}
                >
                  <div className='ibox-title'>
                    <h5>
                      <LocalizedMessage id='annualPlanEdit.tvCampaignParams' />
                    </h5>
                  </div>
                  <div className='ibox-content table-responsive'>
                    {allChannelTypes ? (
                      <TvCampaignParams
                        campaignID={selectedCampaign.id}
                        isLoadingPrices={isLoadingPrices}
                        allChannels={allChannels}
                        channelTypes={allChannelTypes}
                        restrictionBase={selectedCampaign.restrictionBase}
                        params={selectedCampaign.tv.byChannel}
                        sellerRestrictions={sellerRestrictions}
                        retroFrom={selectedCampaign.retroFrom}
                        retroTo={selectedCampaign.retroTo}
                        targetAudience={selectedCampaign.targetAudience!}
                        campaignChannel={selectedCampaign.channel}
                        channelGroups={selectedCampaign.groupRestrictions}
                        year={this.getYearForSelectedCampaign()}
                        onUpdateCampaign={this.updateCampaignByID}
                        onUpdateIsOldRestrictions={
                          this.handleChangeIsOldRestrictions
                        }
                        onUpdatePricesLoadingStatus={
                          this.handleUpdatePricesLoadingStatus
                        }
                        onUpdateValidationStatus={
                          this.handleUpdateTvValidationStatus
                        }
                        onUpdateGroupsValidationStatus={
                          this.handleUpdateGroupsValidationStatus
                        }
                      />
                    ) : null}
                  </div>
                </div>
                ) : null}

              {isValidLengthShares &&
                isValidClosedFrequencies &&
                advertiser &&
                selectedCampaign && (
                  <div
                    className={cx('ibox', {
                      'ibox-invisible': profile.allowedMediaStatus === 'tv',
                      'ibox-disabled': selectedCampaign.tvBudgetPercent === 100
                    })}
                  >
                    <div className='ibox-title'>
                      <h5>
                        <LocalizedMessage id='annualPlanEdit.OLVCampaignParams' />
                      </h5>
                    </div>
                    <div className='ibox-content table-responsive'>
                      <OLVCampaignParams
                        additionalTaOlv={selectedCampaign.additionalTaOlv}
                        onUpdateCampaign={this.updateCampaignByID}
                        targetAudience={selectedCampaign.targetAudience}
                        additionalTA={selectedCampaign.additionalTA}
                        platforms={selectedCampaign.olv}
                        campaignID={selectedCampaign.id}
                        onUpdateValidationStatus={
                          this.handleUpdateOLVValidationStatus
                        }
                        onUpdatePlatforms={this.handleUpdatePlatforms}
                      />
                    </div>
                  </div>
              )}

              <div className='hr-line-dashed' />
              <div
                className={cx(
                  'row bottom-page-row',
                  classes['margin-bottom-9px']
                )}
              >
                <div className='col-lg-12 text-right'>
                  <button
                    className='btn btn-white'
                    onClick={this.cancelEditAnnualPlan}
                  >
                    <LocalizedMessage id='cancel' />
                  </button>
                  &nbsp;
                  {!isCalculating && (
                    <button
                      className='btn btn-success step-button'
                      type='button'
                      disabled={isLoadingTaVolume || isLoadingPrices}
                      onClick={this.saveNoCalculate}
                      data-test='save'
                    >
                      <i className='fa fa-calculator' />
                      &nbsp;
                      <span className='bold'>
                        <LocalizedMessage id='save' />
                      </span>
                    </button>
                  )}
                  &nbsp;
                  {!isOldTvRestrictions ? (
                    <button
                      className='btn btn-success step-button'
                      type='button'
                      disabled={isLoadingTaVolume || isLoadingPrices}
                      onClick={this.saveCalculate}
                      data-test='save'
                    >
                      <i className='fa fa-calculator' />
                      &nbsp;
                      <span className='bold'>
                        <LocalizedMessage id='calculate' />
                      </span>
                    </button>
                  ) : null}
                </div>
              </div>
              {isLoadingTaVolume && (
                <div
                  className={cx(
                    'row bottom-page-row',
                    classes['volume-indicator-row']
                  )}
                >
                  <div className={classes['volume-indicator']}>
                    <Loader
                      isSmall
                      className={classes['volume-indicator-loader']}
                    />
                  </div>
                  <div>
                    <LocalizedMessage id='annualPlanEdit.indicatorMessages.TaLoading' />
                  </div>
                </div>
              )}
              {isLoadingPrices && (
                <div
                  className={cx(
                    'row bottom-page-row',
                    classes['volume-indicator-row']
                  )}
                >
                  <div className={classes['volume-indicator']}>
                    <Loader
                      isSmall
                      className={classes['volume-indicator-loader']}
                    />
                  </div>
                  <div>
                    <LocalizedMessage id='annualPlanEdit.indicatorMessages.TvPricesLoading' />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.auth.profile,
});

export default connect(mapStateToProps)(AnnualPlanEdit);
